import { DecimalPipe } from '@angular/common';
import '@angular/common/locales/global/da';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '@environments/environment';
import { LayoutModule } from '@features/layout/layout.module';
import { getBuildIdQuery } from '@ncg/data';
import {
    apiHttpStateInterceptorProvider,
    apiInterceptorProvider,
    LazyContentModule,
    PageModule,
    PageTransitionModule,
    SpotsConfig,
    TranslateLoaderService,
    UpModule,
} from '@ncg/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const spotsConfigFactory = () => {
    const model = new SpotsConfig();
    model.iconType = 'outlined';
    model.isMetaLinksVisibleMobile = true;
    model.isMobileBackMultiLevel = false;
    model.mobileOverlayOffsetTop = '74px';
    model.invertModelPageHeader = false;
    model.isBrandLayout = true;
    return model;
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'omoda-ncg' }),
        BrowserAnimationsModule,
        LayoutModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLoaderService,
            },
        }),
        NgxJsonLdModule,
        PageModule.forRoot(),
        PageTransitionModule,
        AppRoutingModule,
        StoreModule.forRoot(
            {},
            {
                metaReducers: [],
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictStateImmutability: true,
                },
            }
        ),
        EffectsModule.forRoot([]),
        !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
        IconSpriteModule.forRoot({ path: `assets/images/sprite.svg${getBuildIdQuery()}` }),
        UpModule,
        LazyContentModule,
    ],
    providers: [
        {
            provide: SpotsConfig,
            useFactory: spotsConfigFactory,
            deps: [],
        },
        apiHttpStateInterceptorProvider,
        apiInterceptorProvider,
        {
            provide: LOCALE_ID,
            useValue: 'da-DK',
        },
        DecimalPipe,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
